import React, {
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { BaseSelectStyles as ST } from './styled'
import { BaseSelectTypes as Types } from './types'
import { useInputControlledState } from 'hooks/useInputControlledState'
import BaseCheckbox from 'components/ui/checkboxes/BaseCheckbox'
import useId from '@mui/material/utils/useId'
import { ReactComponent as InfoIcon } from 'assets/icons/Info-circle.svg'

export interface IItem<Value = boolean | number | string, Item = string> {
  item: Item
  value: Value
}

export interface ILabelItem {
  label: string
  value: boolean | number | string
}

const noDataPlaceholder = 'Нет доступных вариантов'

export const SelectDropdownBottomSpaceLimit = 64

// TODO required logic; почему в ST.Select используется <input/>, а не <select> и <option>?
export const BaseSelect = <T,>({
  label,
  placeHolder,
  required,
  isSmallSelect,
  typeSelect,
  listItems,
  passValue,
  setActive,
  activeSelect,
  value,
  onChange,
  name,
  disabled,
  hideArrow,
  removable,
  defaultSelect,
  style,
  inputStyle,
  labelStyle,
  dropdownStyle,
  clickableElements = [],
  closeOnBlur = true,
  fullWidth = false,
  scrollTarget,
  error,
  warn,
  multiple,
  getOptionRequired,
  onClick,
  variant = 'default',
}: Types.Props<T>) => {
  const currentSelectId = useId()
  const listItemsIds = useMemo(
    () =>
      listItems?.map((_, i) =>
        currentSelectId ? `${currentSelectId}_listItem_${i}` : `listItem_${i}`
      ) ?? [],
    [currentSelectId, listItems]
  )
  const defaultIds = useMemo(
    () =>
      new Array(3)
        .fill(null)
        .map((_, index) => `${currentSelectId}_select_${index}`),
    [currentSelectId]
  )
  const selectBlockRef = useRef<ElementRef<'div'>>(null)
  const dropdownRef = useRef<ElementRef<'div'>>(null)

  const [hasEnoughBottomSpace, setHasEnoughBottomSpace] =
    useState<boolean>(false)

  const dropdownStyleValue = useMemo(() => {
    if (!dropdownStyle) return

    const { top, ...dropdownStyleWithoutTopProperty } = dropdownStyle

    return hasEnoughBottomSpace
      ? dropdownStyle
      : dropdownStyleWithoutTopProperty
  }, [dropdownStyle, hasEnoughBottomSpace])

  useEffect(() => {
    const target = scrollTarget ?? document

    const cb = () => {
      if (selectBlockRef.current && dropdownRef.current) {
        const selectRect = selectBlockRef.current.getBoundingClientRect()
        const dropdownRect = dropdownRef.current.getBoundingClientRect()

        setHasEnoughBottomSpace(
          window.innerHeight -
            (selectRect.bottom +
              selectRect.height +
              dropdownRect.height +
              SelectDropdownBottomSpaceLimit) >=
            SelectDropdownBottomSpaceLimit
        )
      }
    }

    cb()

    target.addEventListener('scroll', cb)

    return () => {
      target.removeEventListener('scroll', cb)
    }
  }, [dropdownStyle, scrollTarget])

  const [open, setOpen] = useInputControlledState(false, {
    listenerTargets: [document, ...clickableElements],
    targetIds: [
      ...defaultIds,
      ...listItemsIds,
      ...listItemsIds.map((checkboxId) => `${checkboxId}-checkbox`),
      ...listItemsIds.map((checkboxId) => `${checkboxId}-listItem`),
    ],
    closeOnBlur,
  })

  const [currentValue, setCurrentValue] = useState<Types.SingleValue>(
    (value as Types.SingleValue) ?? ''
  )
  const [currentValues, setCurrentValues] = useState<Types.MultipleValue<T>>(
    listItems.map((item) => ({
      ...item,
      checked: (value as T[])?.includes(item.value),
    }))
  )
  const [visibleItem, setVisibleItem] = useState<number>(-1)

  const handleClickSelect = useCallback((): void => {
    setOpen((p) => !p)
    setActive?.(typeSelect!)
  }, [setActive, setOpen, typeSelect])

  useEffect(() => {
    if (activeSelect && activeSelect !== typeSelect) {
      setOpen(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSelect])

  useEffect(() => {
    if (!multiple) {
      setCurrentValue(value as Types.SingleValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (multiple) {
      setCurrentValues(
        listItems.map((item) => ({
          ...item,
          checked: (value as T[])?.includes(item.value),
        }))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, listItems])

  const handleCheckBoxClick = (index: number) => {
    const newValue = currentValues.map((v, i) =>
      i === index ? { ...v, checked: !v.checked } : v
    )

    if (onChange) {
      const handleChange = onChange as Types.MultipleChangeHandler<T>

      handleChange(
        newValue
          .filter((item) => item.checked)
          .map(({ checked, ...item }) => item)
      )
    }

    setCurrentValues(newValue)
  }

  const handleClick = (
    item: IItem<T> | null,
    index: number,
    type: string | undefined
  ): void => {
    if (!multiple) {
      setVisibleItem(index)
      type && passValue?.(type, item ? item.value : null)

      if (onChange) {
        const handleChange = onChange as Types.SingleChangeHandler<T>

        handleChange(item)
      }

      setCurrentValue(item?.item ?? '')

      setTimeout(() => setOpen((p) => !p), 150)
    }
  }

  useEffect(() => {
    setVisibleItem(listItems.findIndex((item) => item.item === value))
  }, [listItems, value])

  return (
    <ST.SelectBlock style={style} fullWidth={fullWidth} ref={selectBlockRef}>
      {label && (
        <ST.Label
          required={required}
          htmlFor={defaultIds[0]}
          style={labelStyle}
        >
          {label}
        </ST.Label>
      )}
      <ST.Select
        color={error?.length ? 'error' : warn?.length ? 'warn' : undefined}
        style={inputStyle}
        variant={variant}
        id={defaultIds[0]}
        onClick={() => {
          handleClickSelect()
          if (onClick) {
            onClick()
          }
        }}
        name={name}
        isOpen={open}
        placeholder={listItems.length === 0 ? noDataPlaceholder : placeHolder}
        value={
          multiple
            ? currentValues
                .filter((item) => item.checked)
                .map((item) => item.item)
                .join(', ')
            : currentValue ?? ''
        }
        isSmallSelect={isSmallSelect}
        disabled={!!disabled || !listItems.length}
        hideArrow={!!hideArrow || !listItems.length}
        removable={removable}
        defaultSelect={defaultSelect}
        title={
          multiple
            ? currentValues
                .filter((v) => v.checked)
                .map((v) => v.item)
                .join(', ')
            : undefined
        }
        readOnly
      />
      {listItems.length !== 0 ? (
        <ST.DropDownList
          alignToTop={!hasEnoughBottomSpace}
          ref={dropdownRef}
          bottom={SelectDropdownBottomSpaceLimit}
          id={defaultIds[1]}
          isOpen={open}
          isSmallSelect={isSmallSelect}
          removable={removable}
          defaultSelect={defaultSelect}
          style={dropdownStyleValue}
          variant={variant}
        >
          {open ? (
            <>
              {!required && !multiple && (
                <ST.ListItem
                  id={defaultIds[2]}
                  variant={variant}
                  onClick={() => handleClick(null, -1, typeSelect)}
                >
                  Не выбрано
                </ST.ListItem>
              )}
              {multiple
                ? currentValues.map((item, index) => (
                    <ST.MultipleListItem
                      id={listItemsIds[index]}
                      key={`${index}-${item.value}`}
                      onClick={() => handleCheckBoxClick(index)}
                    >
                      <BaseCheckbox
                        checked={item.checked}
                        id={`${listItemsIds[index]}-checkbox`}
                      />
                      <ST.ListItem
                        key={index}
                        variant={variant}
                        id={`${listItemsIds[index]}-listItem`}
                        className={visibleItem === index ? 'active' : ''}
                        required={getOptionRequired?.(item)}
                      >
                        {item.item}
                      </ST.ListItem>
                    </ST.MultipleListItem>
                  ))
                : listItems?.map((item, index) => (
                    <ST.ListItem
                      variant={variant}
                      key={`${index}-${item.value}`}
                      id={listItemsIds[index]}
                      onClick={() => handleClick(item, index, typeSelect)}
                      className={visibleItem === index ? 'active' : ''}
                      required={getOptionRequired?.(item)}
                    >
                      {item.item}
                    </ST.ListItem>
                  ))}
            </>
          ) : null}
        </ST.DropDownList>
      ) : null}

      {!!error?.length ? (
        <ST.Error>{error}</ST.Error>
      ) : (
        !!warn?.length && (
          <ST.WarningWrapper>
            <InfoIcon />
            <ST.Warning>{warn}</ST.Warning>
          </ST.WarningWrapper>
        )
      )}
    </ST.SelectBlock>
  )
}
export default BaseSelect
