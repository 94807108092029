import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { ReactComponent as Plus } from 'assets/icons/plusAddButton.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handlerError, setToastSuccess } from 'utils/handlerError'
import { RequiredFields } from 'constants/requiredFields'
import { ModalTitles } from 'constants/modalTitles'
import { FormikFields } from 'constants/formikFields'
import { InputSizes } from 'constants/inputSizes'
import { ButtonLabels } from 'constants/buttonLabels'
import { Placeholders } from 'constants/placeholders'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { listReviewOperationTypes } from 'constants/reviewOperationTypesDescription'
import { getEmployees } from 'api/employees'
import { parseDataToEmployees, parseUserToEmployees } from 'utils/parseData'
import CalendarInput from 'components/ui/inputs/Calendar'
import moment, { Moment } from 'moment'
import debounce from 'lodash/debounce'
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material'
import { ReviewType, ReviewTypes } from 'types/model/review'
import { editReview } from 'api/review'
import { BreakPoints } from 'constants/breakPoints'
import { autocompleteStyle, autocompleteSx } from 'utils/autocomplete/styles'
import BaseTextarea from 'components/ui/inputs/BaseTextarea'
import { ReviewStatuses } from 'constants/reviewStatuses'
import {
  TaskStatusEnum,
  TaskTypeEnum,
} from 'components/dialogs/Reviews/AddReview'
import { UserTypes } from 'types/model/user'
import { ReviewOperationTypes } from 'constants/reviewsOperationTypes'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import BaseCheckbox from 'components/ui/checkboxes/BaseCheckbox'
import { UserStatuses } from 'constants/userStatuses'
import BaseButton from 'components/ui/buttons/BaseButton'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as DeleteButtonIcon } from 'assets/icons/trash.svg'
import { DATE_TIME_FORMAT_DTO, TIME_FORMAT } from 'constants/Date'
import TimeSelect from 'components/ui/inputs/TimeSelect'

const DEFAULT_EXPERT_VALUE = { item: '', value: 0 }

enum ReviewRequirements {
  MINIMUM_EXPERTS = 2,
  MINIMUM_SURVEY_EXPERTS = 5,
}

interface IRequestAccess {
  show: boolean
  onClose: SetState<boolean>
  updateReviews?: (userId: number) => void
  userId: number
  roles?: string[]
  userList: UserTypes.Model[]
  review: ReviewTypes.Model
  showEditModal?: (event: any) => void
}

enum Fields {
  date = 'date',
  type = 'type',
  typeData = 'typeData',
  task = 'task',
  userData = 'userData',
  userSurvey = 'userSurvey',
  experts = 'experts',
  expertSurvey = 'expertSurvey',
  expertsForSurvey = 'expertsForSurvey',
  comment = 'comment',
}

interface IForm {
  date: Moment | null
  dateEnd: Moment | null
  type: ReviewType
  typeData: IItem<ReviewOperationTypes>
  task: string[]
  user: number
  userSurvey: boolean
  expertSurvey: boolean
  experts: number[]
  expertsForSurvey: number[]
  comment: string
  surveys: ReviewTypes.Survey[]
}

const filterUniqueExperts = (
  expert: ReviewTypes.Expert,
  i: number,
  arr: ReviewTypes.Expert[]
) => arr.findIndex((exp) => exp.id === expert.id) !== i

const EditionReview = ({
  show,
  onClose,
  userId,
  review,
  userList,
  showEditModal,
  updateReviews,
}: IRequestAccess) => {
  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  const [filterUserList, setFilterUserList] = useState<IItem<number>[]>([])
  const [filterUserListSurvey, setFilterUserListSurvey] = useState<
    IItem<number>[]
  >([])

  const reviewExpertsForSurvey = useMemo(
    () => review.surveys?.map((survey) => survey.expert),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [review.surveys, show]
  )

  const initialExperts = useMemo(
    () =>
      review.experts?.length
        ? review.experts.map((expert) => ({
            item: `${expert.surname} ${expert.name}`,
            value: expert.id,
          }))
        : new Array(ReviewRequirements.MINIMUM_EXPERTS).fill(
            DEFAULT_EXPERT_VALUE
          ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [review.experts, show]
  )

  const initialExpertsForSurvey = useMemo(
    () =>
      reviewExpertsForSurvey?.length
        ? reviewExpertsForSurvey
            ?.filter(filterUniqueExperts)
            ?.map((expert) => ({
              item: `${expert.surname} ${expert.name}`,
              value: expert.id,
            }))
        : new Array(ReviewRequirements.MINIMUM_SURVEY_EXPERTS).fill(
            DEFAULT_EXPERT_VALUE
          ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reviewExpertsForSurvey, show]
  )

  const [selectedUsers, setSelectedUsers] =
    useState<IItem<number>[]>(initialExperts)
  const [selectedUsersSurvey, setSelectedUsersSurvey] = useState<
    IItem<number>[]
  >(initialExpertsForSurvey)
  const [employeePoll, setEmployeePoll] = useState<boolean>(
    review?.id ? !!review?.userSurvey?.id : false
  )
  const [generalPoll, setGeneralPoll] = useState<boolean>(
    reviewExpertsForSurvey?.length >= ReviewRequirements.MINIMUM_SURVEY_EXPERTS
  )
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const modalRef = useRef<ElementRef<'div'>>(null)

  const {
    values,
    handleSubmit,
    errors,
    isValid,
    setFieldValue,
    handleChange,
    handleBlur,
    resetForm,
  } = useFormik<IForm>({
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      date: review?.date ? moment(review.date) : null,
      dateEnd: review?.dateEnd ? moment(review.dateEnd) : null,
      type: review?.type,
      typeData: listReviewOperationTypes[review?.type! - 1],
      task:
        review && review.tasks ? review.tasks.map((task) => task.text) : [''],
      user: 0,
      userSurvey: employeePoll,
      expertSurvey: !!review?.surveys.length,
      experts: review?.experts?.filter((expert) => !!expert.id)?.length
        ? review?.experts?.map((expert) => expert.id)
        : new Array(ReviewRequirements.MINIMUM_EXPERTS).fill(
            DEFAULT_EXPERT_VALUE.value
          ),
      expertsForSurvey: review?.surveys.filter((s) => !!s.expert.id)?.length
        ? review?.surveys.map((s) => s.expert.id)
        : new Array<number>(ReviewRequirements.MINIMUM_SURVEY_EXPERTS).fill(
            DEFAULT_EXPERT_VALUE.value
          ),
      comment: review?.comment,
      surveys: review?.surveys || [],
    },
    onSubmit: () => {
      setBtnDisabled(true)
      setIsLoading(true)
      editReview(review?.id!, {
        ...values,
        date: moment(values.date).format(DATE_TIME_FORMAT_DTO),
        dateEnd: moment(values.dateEnd).format(DATE_TIME_FORMAT_DTO),
        type: +values.typeData.value,
        tasks: values.task.map((el) => ({
          type: TaskTypeEnum.THIS_REVIEW,
          text: el,
          status: TaskStatusEnum.NOT_SUCCESS,
        })),
        user: userId,
        userSurvey: employeePoll,
        experts: values.experts.filter(
          (expert) => !!expert && expert !== userId
        ),
        expertSurvey: generalPoll
          ? !!values.expertsForSurvey.filter(
              (expert) => !!expert && expert !== userId
            )?.length
          : false,
        expertsForSurvey: generalPoll
          ? values.expertsForSurvey.filter(
              (expert) => !!expert && expert !== userId
            )
          : [],
      })
        .then(() => {
          setToastSuccess('Ревью успешно изменено')
          handleClose()
          updateReviews?.(userId)
        })
        .catch(() => {
          handlerError(errors)
        })
        .finally(() => {
          setBtnDisabled(false)
          setIsLoading(false)
        })
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().nullable().required(RequiredFields.base),
      dateEnd: Yup.string().nullable().required(RequiredFields.base),
      type: Yup.number().required(RequiredFields.base),
    }),
  })

  const availableExperts = useMemo(
    () =>
      filterUserList.filter(
        (user) =>
          !values.experts.includes(+user.value) && user.value !== review.user.id
      ),
    [filterUserList, review.user.id, values.experts]
  )

  const availableExpertsForSurvey = useMemo(
    () =>
      filterUserListSurvey.filter(
        (user) =>
          !values.expertsForSurvey.includes(+user.value) &&
          user.value !== review.user.id
      ),
    [filterUserListSurvey, review.user.id, values.expertsForSurvey]
  )

  useEffect(() => {
    if (!!userList?.length) {
      setFilterUserList(parseDataToEmployees(userList))
      setFilterUserListSurvey(parseDataToEmployees(userList))
    }
  }, [userList, show])

  const updateTaskList = () => {
    setFieldValue('task', ['', ...values.task])
  }

  const updateExpertsList = () => {
    const experts = [...selectedUsers]

    experts.push(DEFAULT_EXPERT_VALUE)
    setSelectedUsers(experts)
    setFieldValue(
      `experts`,
      experts.map((expert) => expert.value)
    )
  }

  const updateExpertsForSurveyList = () => {
    const experts = [...selectedUsersSurvey]

    experts.push(DEFAULT_EXPERT_VALUE)
    setSelectedUsersSurvey(experts)
    setFieldValue(
      `expertsForSurvey`,
      experts.map((expert) => expert.value)
    )
  }

  useEffect(() => {
    if (!!review?.experts.length) {
      setSelectedUsers(parseUserToEmployees(review?.experts))
    }
    if (!!review?.surveys.length) {
      const list = review?.surveys
        .filter((survey) => survey.expert.id !== review.user.id)
        .map((surv) => surv.expert)
      if (!!list?.length) {
        setSelectedUsersSurvey(parseUserToEmployees(list))
      }
    }
  }, [review?.experts, review?.surveys, review.user.id])

  const isExpertsValid = useMemo(
    () =>
      selectedUsers.filter((u) => u.value !== 0)?.length >=
      ReviewRequirements.MINIMUM_EXPERTS,
    [selectedUsers]
  )

  const isExpertsForSurveyValid = useMemo(
    () =>
      generalPoll
        ? selectedUsersSurvey.filter((u) => u.value !== 0)?.length >=
          ReviewRequirements.MINIMUM_SURVEY_EXPERTS
        : true,
    [selectedUsersSurvey, generalPoll]
  )

  const checkForm = (): void => {
    if (isValid && isExpertsForSurveyValid && isExpertsValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const resetAll = () => {
    resetForm()
    setSelectedUsers(initialExperts)
    setSelectedUsersSurvey(initialExpertsForSurvey)
    setEmployeePoll(review?.id ? !!review?.userSurvey?.id : false)
    setGeneralPoll(!!values.expertsForSurvey.filter((expert) => expert).length)
  }

  const handleClose = (): void => {
    onClose(!show)
    resetAll()
  }

  const handleInputChange = (value: string, isSurvey: boolean) => {
    if (value !== undefined) {
      if (isSurvey) {
        getEmployees(1, { status: [UserStatuses.active] }, value)
          .then((data) => {
            setFilterUserListSurvey(parseDataToEmployees(data.data))
          })
          .catch((e) => {
            handlerError(e)
          })
      } else {
        getEmployees(1, { status: [UserStatuses.active] }, value)
          .then((data) => {
            setFilterUserList(parseDataToEmployees(data.data))
          })
          .catch((e) => {
            handlerError(e)
          })
      }
    }
  }

  const clearInputChange = (isSurvey: boolean) => {
    getEmployees(1, { status: [UserStatuses.active] })
      .then((data) => {
        isSurvey
          ? setFilterUserListSurvey(parseDataToEmployees(data.data))
          : setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  show && console.log(values, isValid)

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent btnDisabled={btnDisabled}>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{ModalTitles.editReview}</ST.ModalTitle>
          <ST.Label>
            <ST.LabelName>Сотрудник:</ST.LabelName>
            <ST.LabelValue>
              {review.user.surname} {review.user.name}
            </ST.LabelValue>
          </ST.Label>
          <ST.InputsBlock>
            <ST.FlexBlock>
              <ST.InputWrapper>
                <ST.LabelWrapper>
                  <ST.Label>
                    Дата ревью<ST.Star>*</ST.Star>
                  </ST.Label>
                  <ST.ErrorText>{errors.date}</ST.ErrorText>
                </ST.LabelWrapper>
                <CalendarInput
                  startDate={values.date?.toDate() ?? new Date()}
                  value={values.date}
                  onChange={(newDate) => {
                    const value = newDate
                      ? typeof newDate === 'string'
                        ? new Date(newDate)
                        : newDate instanceof Date
                        ? newDate
                        : newDate.toDate()
                      : null

                    const newDateValue = moment(value)

                    setFieldValue(
                      'date',
                      moment(newDateValue).set({
                        hour: values.date?.get('hour'),
                        minute: values.date?.get('minute'),
                      })
                    )
                    setFieldValue(
                      'dateEnd',
                      moment(newDateValue).set({
                        hour: values.dateEnd?.get('hour'),
                        minute: values.dateEnd?.get('minute'),
                      })
                    )
                  }}
                />
              </ST.InputWrapper>
              <ST.InputWrapper>
                <TimeSelect
                  value={values.date?.format(TIME_FORMAT) ?? null}
                  dateValue={values.date ?? undefined}
                  label="Начало ревью"
                  onChange={(val) => {
                    if (val?.value) {
                      setFieldValue(
                        'date',
                        (values.date ? moment(values.date) : moment()).set({
                          hour: val?.value.get('hour'),
                          minute: val?.value.get('minute'),
                        })
                      )
                    }
                  }}
                  clickableElements={[modalRef.current]}
                  scrollTarget={modalRef.current}
                  dropdownStyle={{ top: 70 }}
                  required
                />
              </ST.InputWrapper>
              <ST.InputWrapper>
                <TimeSelect
                  value={values.dateEnd?.format(TIME_FORMAT) ?? null}
                  dateValue={values.dateEnd ?? undefined}
                  label="Окончание ревью"
                  onChange={(val) => {
                    if (val?.value) {
                      setFieldValue(
                        'dateEnd',
                        (values.dateEnd
                          ? moment(values.dateEnd)
                          : moment()
                        ).set({
                          hour: val?.value.get('hour'),
                          minute: val?.value.get('minute'),
                        })
                      )
                    }
                  }}
                  clickableElements={[modalRef.current]}
                  scrollTarget={modalRef.current}
                  dropdownStyle={{ top: 70 }}
                  required
                />
              </ST.InputWrapper>
            </ST.FlexBlock>

            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>
                  Тип ревью<ST.Star>*</ST.Star>
                </ST.Label>
                <ST.ErrorText>{errors.type}</ST.ErrorText>
              </ST.LabelWrapper>
              <BaseSelect
                value={values?.typeData?.item || ''}
                isSmallSelect={false}
                placeHolder={Placeholders.reviewType}
                listItems={listReviewOperationTypes}
                name={FormikFields.reviewType}
                typeSelect={FormikFields.reviewType}
                passValue={(_, b) => {
                  setFieldValue('typeData', {
                    item: listReviewOperationTypes.find((e) => e.value === b)
                      ?.item,
                    value: b,
                  })
                  setFieldValue('type', b)
                }}
              />
            </ST.InputWrapper>

            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>Задачи</ST.Label>
                <ST.ErrorText>{errors.task}</ST.ErrorText>
              </ST.LabelWrapper>
              <ST.ListWrapper>
                {values.task.map((taskValue, index) => (
                  <ST.InputWrapperWithDelete isBlur key={`task-${index}`}>
                    <ST.Input
                      key={index}
                      type="text"
                      placeholder={Placeholders.taskForReview}
                      sizeInput={InputSizes.big}
                      name={`task[${index}]`}
                      value={taskValue}
                      onChange={(e) => {
                        const tasks = [...values.task]
                        tasks[index] = e?.target.value
                        setFieldValue('task', tasks)
                      }}
                    />
                    <IconButton
                      icon={DeleteButtonIcon}
                      onClick={() =>
                        setFieldValue(
                          'task',
                          values.task.filter((_, i) => i !== index)
                        )
                      }
                    />
                  </ST.InputWrapperWithDelete>
                ))}
              </ST.ListWrapper>

              <ST.AddButton
                onClick={updateTaskList}
                disabled={values.task.some((t) => !t.length)}
              >
                <Plus />
                Добавить задачу
              </ST.AddButton>
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>
                  Интервьюеры, присутствующие на ревью<ST.Star>*</ST.Star>
                </ST.Label>
                <ST.ErrorText>{errors.experts}</ST.ErrorText>
              </ST.LabelWrapper>
              {values.experts?.map((expertsValue, index, array) => (
                <ST.ExpertWrapper key={`expert-${index}`}>
                  <ST.ExpertInputWrapper>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      clearOnBlur={false}
                      blurOnSelect
                      noOptionsText={'Нет доступных вариантов'}
                      options={availableExperts}
                      style={autocompleteStyle(isMobileDevice)}
                      sx={autocompleteSx(isMobileDevice)}
                      filterSelectedOptions
                      value={selectedUsers[index]}
                      onInputChange={(ev, v, r) => {
                        if (r === 'input') {
                          debounce(() => handleInputChange(v, false), 1000)()
                        } else if (r === 'clear') {
                          clearInputChange(false)
                        }
                      }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.value === value?.value
                      }
                      filterOptions={(options: any) => options}
                      getOptionLabel={(option: any) => option.item}
                      onChange={(event, value: any) => {
                        const experts = [...selectedUsers]
                        experts[index] = value ?? DEFAULT_EXPERT_VALUE
                        setSelectedUsers(experts)
                        setFieldValue(
                          'experts',
                          experts.map((expert) => expert.value)
                        )
                      }}
                      renderOption={(props: any, option: any) => (
                        <Box
                          {...props}
                          component="li"
                          style={{
                            backgroundColor: '#fefefe',
                            border: 'none',
                            color: '#898989',
                          }}
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          key={option.value}
                        >
                          {option.item}
                        </Box>
                      )}
                      renderInput={(params: any) => (
                        <TextField
                          style={{
                            backgroundColor: '#f8f8f8',
                            borderWidth: 0,
                            '::placeholder': {
                              opacity: '0.5',
                            },
                            borderRadius: '10px',
                          }}
                          {...params}
                          label="Выберите сотрудника"
                        />
                      )}
                    />
                  </ST.ExpertInputWrapper>
                  <IconButton
                    icon={DeleteButtonIcon}
                    disabled={
                      array.length <= ReviewRequirements.MINIMUM_EXPERTS
                    }
                    onClick={() => {
                      if (array.length > ReviewRequirements.MINIMUM_EXPERTS) {
                        setFieldValue(
                          'experts',
                          values.experts.filter((_, i) => i !== index)
                        )
                        selectedUsers.length > 1 &&
                          setSelectedUsers(
                            selectedUsers.filter((_, i) => i !== index)
                          )
                      }
                    }}
                  />
                </ST.ExpertWrapper>
              ))}
              <ST.AddButton
                onClick={updateExpertsList}
                disabled={
                  (!!selectedUsers.length &&
                    selectedUsers.some((e) => e.value === 0)) ||
                  !availableExperts?.length
                }
              >
                <Plus />
                Добавить эксперта
              </ST.AddButton>
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>Опросы:</ST.Label>
              </ST.LabelWrapper>
              <ST.PollsWrapper>
                <BaseCheckbox
                  label="Опрос сотрудника"
                  checked={employeePoll}
                  onChange={() => {
                    setEmployeePoll((prevState) => !prevState)
                  }}
                />
                <BaseCheckbox
                  label="Опрос 360"
                  checked={generalPoll}
                  onChange={() => {
                    setGeneralPoll((prevState) => !prevState)
                  }}
                />
              </ST.PollsWrapper>
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.GeneralEmployeesWrapper isOpen={generalPoll}>
                <ST.LabelWrapper>
                  <ST.Label>
                    Независимые эксперты опроса 360<ST.Star>*</ST.Star>
                  </ST.Label>
                  <ST.ErrorText>{errors.experts}</ST.ErrorText>
                </ST.LabelWrapper>
                {selectedUsersSurvey?.map(
                  (expertsForSurveyValue, index, array) => (
                    <ST.ExpertWrapper key={`expert-${index}`}>
                      <ST.ExpertInputWrapper>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          clearOnBlur={false}
                          blurOnSelect
                          noOptionsText={'Нет доступных вариантов'}
                          options={availableExpertsForSurvey}
                          style={autocompleteStyle(isMobileDevice)}
                          sx={autocompleteSx(isMobileDevice)}
                          filterSelectedOptions
                          value={expertsForSurveyValue}
                          onInputChange={debounce(
                            (e: any) =>
                              handleInputChange(e?.target?.value, true),
                            1000
                          )}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.value === value.value
                          }
                          filterOptions={(options: any) => options}
                          getOptionLabel={(option: any) => option.item}
                          onChange={(event, value: any) => {
                            const experts = [...selectedUsersSurvey]
                            experts[index] = value ?? DEFAULT_EXPERT_VALUE
                            setSelectedUsersSurvey(experts)
                            setFieldValue(
                              'expertsForSurvey',
                              experts.map((expert) => expert.value)
                            )
                          }}
                          renderOption={(props: any, option: any) => (
                            <Box
                              {...props}
                              component="li"
                              style={{
                                backgroundColor: '#fefefe',
                                border: 'none',
                                color: '#898989',
                              }}
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              key={option.value}
                            >
                              {option.item}
                            </Box>
                          )}
                          renderInput={(params: any) => (
                            <TextField
                              style={{
                                backgroundColor: '#f8f8f8',
                                borderWidth: 0,
                                '::placeholder': {
                                  opacity: '0.5',
                                },
                                borderRadius: '10px',
                              }}
                              {...params}
                              label="Выберите сотрудника"
                            />
                          )}
                        />
                      </ST.ExpertInputWrapper>
                      <IconButton
                        icon={DeleteButtonIcon}
                        disabled={
                          array.length <=
                          ReviewRequirements.MINIMUM_SURVEY_EXPERTS
                        }
                        onClick={() => {
                          if (
                            array.length >
                            ReviewRequirements.MINIMUM_SURVEY_EXPERTS
                          ) {
                            setFieldValue(
                              'expertsForSurvey',
                              values.expertsForSurvey.filter(
                                (_, i) => i !== index
                              )
                            )
                            selectedUsersSurvey.length > 1 &&
                              index !== 0 &&
                              setSelectedUsersSurvey(
                                selectedUsersSurvey.filter(
                                  (_, i) => i !== index
                                )
                              )
                          }
                        }}
                      />
                    </ST.ExpertWrapper>
                  )
                )}
                <ST.AddButton
                  onClick={updateExpertsForSurveyList}
                  disabled={
                    (!!selectedUsersSurvey.length &&
                      selectedUsersSurvey.some((e) => e.value === 0)) ||
                    !availableExpertsForSurvey?.length
                  }
                >
                  <Plus />
                  Добавить эксперта
                </ST.AddButton>
              </ST.GeneralEmployeesWrapper>
            </ST.InputWrapper>
            <ST.InputWrapper>
              <BaseTextarea
                rows={6}
                id={Fields.comment}
                name={Fields.comment}
                label="Комментарий"
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </ST.InputWrapper>
          </ST.InputsBlock>
          <ST.WrapperButtons>
            <BaseButton
              text={ButtonLabels.save}
              onClick={checkForm}
              disabled={
                btnDisabled ||
                !isExpertsForSurveyValid ||
                !isExpertsValid ||
                !isValid
              }
            />

            {review.status === ReviewStatuses.not_complete && (
              <BaseButton
                typeButton="secondary"
                text={ButtonLabels.closeReview}
                onClick={showEditModal}
              />
            )}
          </ST.WrapperButtons>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default EditionReview
