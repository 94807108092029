import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Endpoint } from 'constants/endpoint'
import { redirectToServiceMode } from 'utils/redirectToServiceMode'
import { setToastError } from 'utils/handlerError'
import { ApiError } from 'store/api/types/ApiError'
import { AuthActionCreators } from 'store/actions/auth'
import { PATHS } from 'constants/paths'
import getIntegerSearchParam from 'utils/url/getIntegerSearchParam'
import { ResponseErrorMessages } from 'constants/responseErrorMessages'
import { useNavigate } from 'react-router-dom'

type ServerError = ApiError & {
  originalStatus?: number
  error?: string
}

const TAG_TYPES = [
  'Reply',
  'TechnologyStack',
  'Application',
  'Grade',
  'Vacancy',
  'Action',
  'Setting',
  'ServiceMode',
  'Benefit',
  'Workplace',
  'Resume',
  'Profdev',
  'Review',
  'User',
  'Position',
  'Department',
] as const

const baseQuery = fetchBaseQuery({
  baseUrl: `${Endpoint}api`,
  prepareHeaders: (headers) => {
    // Получение токена из localStorage или из Redux store
    const token = JSON.parse(localStorage.getItem('auth') || '{}')?.accessToken
    // Если токен существует, добавляем его в заголовки запроса
    if (token) {
      headers.set('apiKey', token)
    }
    return headers
  },
})

let displayedErrors: string[] = []

const setErrors = (value: string) => {
  displayedErrors = displayedErrors.includes(value)
    ? displayedErrors
    : [...displayedErrors, value]
}

const isReviewEdit =
  window.location.pathname.includes(PATHS.reviews) &&
  getIntegerSearchParam('edit')

export const baseApi = createApi({
  reducerPath: 'api',
  refetchOnReconnect: true,
  keepUnusedDataFor: 120,
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions)

      localStorage.setItem('service', 'false')

      if (result.error) {
        const fetchError = result.error as ServerError

        const errorMessage =
          fetchError.data?.validationError?.body?.[0]?.message ||
          fetchError.data?.message

        if (
          fetchError.status !== 403 &&
          fetchError.data &&
          !displayedErrors.includes(errorMessage)
        ) {
          setToastError(errorMessage)
          setErrors(errorMessage)
        }

        if (
          (fetchError.status === 403 && !isReviewEdit) ||
          (fetchError.data?.message ===
            ResponseErrorMessages.ERROR_403_EXPIRED &&
            fetchError.status === 401)
        ) {
          api.dispatch(AuthActionCreators.logout())
          setToastError('Необходима повторная авторизация')
        }

        if (fetchError.originalStatus === 500 && fetchError.error) {
          setToastError(`HTTP 500: ${fetchError.error}`)
        }

        if (fetchError.status === 433) {
          localStorage.setItem('service', 'true')
          redirectToServiceMode()
        }
      }

      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  tagTypes: TAG_TYPES,
  endpoints: () => ({}),
})
