import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import Check from 'assets/icons/employmentPage/check.svg'
import ArrowUp from 'assets/icons/employmentPage/arrow_up.svg'
import ArrowDown from 'assets/icons/employmentPage/arrow_down.svg'
import { BreakPoints } from 'constants/breakPoints'
import { BaseSelectTypes as Types } from './types'

export namespace BaseSelectStyles {
  type SeverityColors = 'warn' | 'error'

  interface ISelect {
    isOpen: boolean
    isSmallSelect?: boolean
    hideArrow?: boolean
    removable?: boolean
    defaultSelect?: boolean
    color?: SeverityColors
    alignToTop?: boolean
    bottom?: number
    variant: Types.SelectVariant
  }

  interface LabelProps {
    required?: boolean
  }

  interface FullWidthProps {
    fullWidth?: boolean
  }

  interface ListItemProps {
    required?: boolean
    variant: Types.SelectVariant
  }

  const fullWidthStyles = css`
    width: 100%;
  `

  export const Label = styled.label<LabelProps>`
    font-weight: normal;
    padding-bottom: 6px;
    display: flex;
    align-items: start;
    font-size: 14px;
    line-height: 140%;
    color: ${COLORS.black70};

    ${({ required }) =>
      required &&
      css`
        &::after {
          content: '*';
          color: ${COLORS.errorRed};
          margin-left: 4px;
        }
      `};
  `

  export const SelectBlock = styled.div<FullWidthProps>`
    position: relative;
    ${({ fullWidth }) => fullWidth && fullWidthStyles};
    min-width: 244px;

    @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
      width: 100%;
    }
  `

  export const DropDownList = styled.div<ISelect>`
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    width: 100%;
    max-width: ${(props) =>
      props.isSmallSelect
        ? '240px'
        : props.removable
        ? '456px'
        : props.defaultSelect
        ? '300px'
        : '100%'};
    box-sizing: border-box;
    background: ${COLORS.white};
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
    padding: ${({ variant }) => (variant === 'default' ? '10px 20px' : '10px')};
    flex-direction: column;
    gap: 12px;
    position: absolute;
    z-index: 2;
    ${({ alignToTop }) => (alignToTop ? 'bottom: 42px;' : 'top: 30px;')};
    overflow-y: auto;
    min-height: 32px;
    max-height: ${(props) =>
      props.isSmallSelect
        ? '220px'
        : props.removable
        ? '436px'
        : props.defaultSelect
        ? '280px'
        : '480px'};
    scrollbar-width: thin;
    scrollbar-color: transparent white;
    cursor: default;

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
    }

    :hover {
      ::-webkit-scrollbar-thumb {
        background-color: gray;
      }

      scrollbar-color: gray white;
    }

    @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
      top: 40px;
      max-width: initial;
    }
  `

  export const MultipleListItem = styled.div`
    display: flex;
    gap: 8px;
  `

  export const ListItem = styled.p<ListItemProps>`
    font-size: 16px;
    line-height: 140%;
    color: ${({ variant }) =>
      variant === 'default' ? COLORS.gray70 : COLORS.black};
    position: relative;
    padding: ${({ variant }) => (variant === 'default' ? 'initial' : '0 9px')};
    transition: all 0.15s ease-in-out;

    :hover {
      cursor: pointer;
      color: ${({ variant }) =>
        variant === 'default' ? COLORS.black70 : COLORS.black};
      background-color: ${({ variant }) =>
        variant === 'default' ? 'initial' : COLORS.yellow50};
      border-radius: ${({ variant }) => (variant === 'default' ? 0 : '4px')};
    }

    ${({ required }) =>
      required &&
      css`
        &::after {
          content: '*';
          color: ${COLORS.errorRed};
          margin-left: 4px;
        }
      `};

    &.active {
      color: ${COLORS.black};
      ${({ variant }) =>
        variant === 'default'
          ? css`
              :after {
                content: '';
                background: url(${Check});
                width: 24px;
                height: 24px;
                right: 0;
                position: absolute;
              }
            `
          : css`
              background-color: ${COLORS.yellow50};
              border-radius: 4px;
            `};
    }
  `

  export const Select = styled.input<ISelect & FullWidthProps>`
    box-sizing: border-box;
    border-radius: 10px;
    border: ${({ color }) =>
      color === 'warn'
        ? `2px solid ${COLORS.yellowHover}`
        : color === 'error'
        ? `2px solid ${COLORS.errorRed}`
        : 'none'};
    background: ${(props) =>
      props.isOpen
        ? `${COLORS.white} ${
            props.hideArrow ? '' : `url(${ArrowUp})`
          } 98% 50% no-repeat`
        : `${COLORS.inputBG} ${
            props.hideArrow ? '' : `url(${ArrowDown})`
          } 98% 50% no-repeat`};
    box-shadow: ${(props) =>
      props.isOpen ? '0 -1px 8px rgba(0, 0, 0, 0.1)' : 'null'};
    padding: 10px 20px;
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    width: 100%;
    max-width: ${(props) =>
      props.isSmallSelect
        ? '240px'
        : props.removable
        ? '456px'
        : props.defaultSelect
        ? '300px'
        : props.fullWidth
        ? '100%'
        : '500px'};
    color: #000000;

    ::placeholder {
      opacity: 0.5;
    }

    :hover {
      cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    }

    @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
      height: 48px;
      padding: 14px 16px 12px;
      max-width: initial;
    }
  `

  export const Error = styled.p`
    color: ${COLORS.errorRed};
    font-size: 14px;
    margin: 4px 0 0 4px;
  `

  export const WarningWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    line-height: 18px;
    margin-top: 4px;
  `

  export const Warning = styled(Error)`
    margin: 0;
    color: ${COLORS.black};
  `
}
