import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { FIX_CHROME_BLUR } from 'constants/fixChromeBlur'
import { CommonStyles } from 'styles/CommonStyles'

type ModalProps = {
  $open: boolean
}

type ParagraphProps = {
  underline?: boolean
  pointer?: boolean
}

type ButtonProps = {
  disabled?: boolean
  outlined?: boolean
}

type LabelProps = {
  $required?: boolean
}

export const ChangeStatusModalOverlay = styled.div<ModalProps>`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
  justify-content: center;
`

export const ChangeStatusModal = styled.div<ModalProps>`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: ${FIX_CHROME_BLUR};
  max-width: 580px;
  width: 100%;
  max-height: 95%;
  overflow: auto;
  background: ${COLORS.white};
  border-radius: 15px;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ $open }) => ($open ? 1 : 0)};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
  }
`

export const ModalParagraph = styled.p<
  ParagraphProps & CommonStyles.MaxWidthProps
>`
  ${CommonStyles.WrappedText};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};

  &:first-of-type {
    margin-bottom: 8px;
  }
`

export const ModalContent = styled.div`
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-sizing: border-box;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
  margin-bottom: 10px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.26px;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

export const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const Button = styled.button<ButtonProps>`
  width: 100%;
  background-color: ${({ disabled, outlined }) =>
    outlined ? 'transparent' : disabled ? COLORS.yellow50 : COLORS.yellow};
  height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ disabled }) => (disabled ? COLORS.black50 : COLORS.black)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  border: ${({ outlined }) =>
    outlined ? `1px solid ${COLORS.yellow}` : 'none'};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-top: auto;
  }

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ disabled, outlined }) =>
      !disabled && (outlined ? 'transparent' : COLORS.yellowHover)};
  }
`

export const InputTextArea = styled.textarea`
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  width: 100%;
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 140px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;

  &::placeholder {
    opacity: 0.5;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 14px 16px 12px;
  }
`

export const Label = styled.p<LabelProps>`
  font-weight: normal;
  display: flex;
  gap: 4px;
  align-items: start;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 3px;

  &::after {
    content: ${({ $required }) => ($required ? '"*"' : '')};
    color: ${COLORS.errorRed};
    font-size: 14px;
    font-weight: 500;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const ErrorText = styled.div`
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
`

export const ModalInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 30px;
`

export const InputsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ModalAddFiles = styled.div`
  display: flex;
  flex-direction: column;

  gap: 18px;

  & > p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -8px;
  }
`

export const ModalFile = styled.div``

export const ModalFileInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const ModalFileInput = styled.input`
  border-radius: 10px;
  font-size: 16px;
  line-height: 140%;
  background-color: ${COLORS.lightGrey20};
  width: 100%;
  height: 100%;
  padding: 10px 20px 8px 20px;
`

export const ModalDeleteFile = styled.div`
  cursor: pointer;
`

export const HiddenFileInput = styled.input``

export const LoadFileCtr = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
`

export const LoadFile = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  svg {
    max-width: 26px;
  }
`

export const FileName = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: underline;
  align-items: center;
  font-size: 14px;
  gap: 3px;
  margin-left: 36px;
`

export const ExpertInputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  > div > input {
    width: 406px;
  }
  > input {
    width: 406px;
  }
  > div > div {
    width: 456px;
  }
`

interface InputWrapperProps {
  isBlur: number
}

export const ExpertWrapper = styled.div<InputWrapperProps>`
  display: flex;
  width: 500px;
  gap: 10px;
  margin-bottom: 10px;
  > svg {
    opacity: ${({ isBlur }) => (isBlur ? '1' : '0.5')};
    cursor: ${({ isBlur }) => isBlur && 'pointer'};
  }
`

export const DeleteButtonWrapper = styled.div<{ $disabled?: boolean }>`
  transform: translateY(8px);
  cursor: pointer;
  opacity: ${({ $disabled }) => (!$disabled ? '1' : '0.5')};
`

export const Review = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -8px;
  }
`

export const TextInput = styled.textarea`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 10px;
  background: ${COLORS.inputBG};
  height: 42px;
  padding: 10px 20px 24px 20px;
  border-color: transparent;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
`

export const InputsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`
