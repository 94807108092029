import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BaseButton from 'components/ui/buttons/BaseButton'
import { PATHS } from 'constants/paths'
import { handlerError, setToastError } from 'utils/handlerError'
import { RequiredFields } from 'constants/requiredFields'
import { ButtonLabels } from 'constants/buttonLabels'
import { ButtonTypes } from 'constants/buttonTypes'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import Body from 'components/ui/layout/Body'
import { useEditGradeMutation, useGetGradeByIdQuery } from 'store/api/grade'
import Loader from 'components/ui/Loader'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left.svg'
import BaseInput from 'components/ui/inputs/BaseInput'

const inputName = 'name'

type Form = {
  name: string
  bonus: string | number | null
}

const EditGrade = () => {
  useTitle(PAGES.editGrade)
  const navigate = useNavigate()
  const { gradeId } = useParams()
  const {
    data: grade,
    error,
    isLoading,
  } = useGetGradeByIdQuery(Number(gradeId))

  const [editGrade] = useEditGradeMutation()

  const { handleChange, values, handleSubmit, isValid } = useFormik<Form>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      name: grade?.name || '',
      bonus: grade?.recruiterPremium ?? '',
    },
    onSubmit: (data) => {
      editGrade({
        id: +gradeId!,
        data: {
          name: data.name,
          recruiterPremium: +String(data.bonus!).split(' ').join(''),
        },
      })
        .then(() => {
          navigate(PATHS.grades)
        })
        .catch((err) => handlerError(err))
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(RequiredFields.base),
      bonus: Yup.string().nullable().required(RequiredFields.base),
    }),
  })

  useEffect(() => {
    error && setToastError(String(error))
  }, [error])

  return (
    <Body>
      <ST.EditGradeHead>
        <ST.HeadGrade>
          <IconButton icon={BackIcon} onClick={() => navigate(PATHS.grades)} />
          <ST.TitleGrade>редактирование</ST.TitleGrade>
        </ST.HeadGrade>
        <ST.Button>
          <BaseButton
            type={'submit'}
            text={ButtonLabels.save}
            typeButton={ButtonTypes.primary}
            onClick={handleSubmit}
            disabled={!isValid}
          />
        </ST.Button>
      </ST.EditGradeHead>
      <ST.EditGradeBody>
        {isLoading ? (
          <Loader />
        ) : (
          <ST.GradeHead>
            <BaseInput
              value={values.name}
              label="Название грейда"
              id={inputName}
              name={inputName}
              placeholder="Введите название"
              onChange={handleChange}
              required
            />
            <BaseInput
              thousandsGroupStyle="thousand"
              thousandSeparator=" "
              value={values.bonus ?? ''}
              type="number"
              label="Премия для отдела рекрутинга, руб"
              placeholder="Введите сумму"
              id="bonus"
              name="bonus"
              onChange={handleChange}
              required
            />
          </ST.GradeHead>
        )}
      </ST.EditGradeBody>
    </Body>
  )
}

export default EditGrade
