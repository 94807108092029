export enum SettingsSteps {
  GENERAL = 1,
  CONTACTS = 2,
  DOCUMENT_PATTERNS = 3,
  HIRING_STAGES = 4,
  HARDWARE_TYPES = 5,
  INTEGRATIONS = 6,
  BENEFIT_TYPES = 7,
  LETTER_TEMPLATES = 8,
  HIRING_TYPES = 9,
}

export enum HiringStepsEnum {
  INITIAL_STATUS = 1,
  OFFER = 2,
  REJECTION = 3,
  CLOSE_VACANCY = 4,
  ACCEPTED = 5,
  RESUME_COMPLETION = 6,
}

export enum IntegrationSteps {
  MAIL = 1,
  CALENDAR = 2,
  REVIEWS = 3,
  VK_BOT = 4,
  API_HH = 5,
  API_HABR = 6,
  AVITO = 7,
  TELEGRAM,
}
